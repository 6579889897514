import i18n from 'i18next';

import publicTranslations from './locales/public.json';
import productsTranslations from './locales/products.json';
import guideTranslations from './locales/guide.json';
import sharedTranslations from './locales/shared.json';

const resources = {
  main: {
    translation: {
      ...publicTranslations,
      ...productsTranslations,
      ...guideTranslations,
      ...sharedTranslations,
    },
  },
};

const isDevEnv = process.env.NODE_ENV === 'local';

i18n.init({
  resources,
  lng: 'main',
  parseMissingKeyHandler: isDevEnv ? null : () => '',
  debug: isDevEnv,
  // fallbackLng: 'sv',
  appendNamespaceToMissingKey: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
