import fetch from 'node-fetch';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

let uri = process.env.GATSBY_BE_URL;
if (process.env.GATSBY_BW_ENV === 'local') {
  uri = process.env.GATSBY_BE_URL_DEV;
}

const httpLink = createHttpLink({
  uri,
  fetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-businesswith-locale': process.env.GATSBY_LOCALE,
    'x-build-api-key': process.env.BUILD_API_KEY,
  },
}));

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client, gql };
