import React from 'react';
import 'whatwg-fetch';
import { v4 as uuidv4 } from 'uuid';

import { I18nextProvider } from 'react-i18next';

import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo-client';

import i18n from './src/i18next';
import { GlobalStyles } from './src/ui/styles';
import './src/styles/global.css';

function albacrossReveal() {
  const locale = process.env.GATSBY_LOCALE;

  let timeout = 0;
  function poll() {
    if (!(timeout > 60000)) {
      if (!window.AlbacrossReveal || !window.Cookiebot?.consented) {
        timeout += 50;
        return setTimeout(poll, 50);
      }
      if (!window.AlbacrossReveal.company) {
        timeout += 50;
        return setTimeout(poll, 50);
      }
    }

    try {
      if (window.AlbacrossReveal.company) {
        const companyName = window.AlbacrossReveal.company.name;
        const companyCountry = window.AlbacrossReveal.company.country;
        const companyFounded = window.AlbacrossReveal.company.founded_year;
        const companyAlexaRank = window.AlbacrossReveal.company.alexa_rank;
        const companyEmployeeMin = window.AlbacrossReveal.company.employees
          ? window.AlbacrossReveal.company.employees.from
          : '';
        const companyEmployeeMax = window.AlbacrossReveal.company.employees
          ? window.AlbacrossReveal.company.employees.to
          : '';
        const companyLinkedInCategory = window.AlbacrossReveal.company
          .linkedin_industry_code
          ? window.AlbacrossReveal.company.linkedin_industry_code.category
          : '';
        const companyLinkedInCode = window.AlbacrossReveal.company
          .linkedin_industry_code
          ? `${window.AlbacrossReveal.company.linkedin_industry_code.code}`
          : '';

        const companyFinancialsMin = window.AlbacrossReveal.company
          .financial_report
          ? window.AlbacrossReveal.company.financial_report.from
          : '';
        const companyFinancialsMax = window.AlbacrossReveal.company
          .financial_report
          ? window.AlbacrossReveal.company.financial_report.to
          : '';
        const companNACECategory = window.AlbacrossReveal.company.nace_code
          ? window.AlbacrossReveal.company.nace_code.category
          : '';
        const companyCity = window.AlbacrossReveal.company.address.city;
        const companyState = window.AlbacrossReveal.company.address.state;
        const companyOrgID = window.AlbacrossReveal.company.number;
        const companyZip = window.AlbacrossReveal.company.address.zip_code
          ? window.AlbacrossReveal.company.address.zip_code
          : '';
        const companyWebsite = window.AlbacrossReveal.company.url
          ? window.AlbacrossReveal.company.url
          : '';

        const linkedInUrl =
          window?.AlbacrossReveal?.company?.linkedin_url || '';

        const query = JSON.stringify({
          query: `mutation {
            createLeadsAlbacrossReveal(
              name: "${companyName}"
              founded: "${companyFounded || ''}"
              country: "${companyCountry}"
              alexaRank: "${companyAlexaRank || ''}"
              employeeMin: "${companyEmployeeMin}"
              employeeMax: "${companyEmployeeMax}"
              linkedInCategory: "${companyLinkedInCategory}"
              linkedInCategoryCode: "${companyLinkedInCode}"
              linkedInUrl: "${linkedInUrl}"
              financialsMin: "${companyFinancialsMin}"
              financialsMax: "${companyFinancialsMax}"
              naceCategory: "${companNACECategory}"
              city: "${companyCity || ''}"
              state: "${companyState || ''}"
              orgId: "${companyOrgID || ''}"
              zip: "${companyZip || ''}"
              website: "${companyWebsite}"
              locale: "${locale}"
              url: "${window.location.href}"
							sessionId: "${sessionStorage.getItem('sessionId')}"
            ) { result }
          }
          `,
        });

        fetch(process.env.GATSBY_BE_URL_DEV, {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: query,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((response) => {
            const id = response?.data?.createLeadsAlbacrossReveal?.result;
            sessionStorage.setItem('reveal', id);
          });
      }
    } catch (error) {
      console.log('Error with A');
    }

    return true;
  }
  poll();
}

// function albacrossRevealDev() {
//   const locale = process.env.GATSBY_LOCALE;
//   try {
//     const randomRevenue =
//       Math.floor(Math.round(Math.random(), 2) * 10000000) + 1000001;
//     const randomRevenueMax =
//       randomRevenue +
//       1000000 *
//         Math.round(
//           (randomRevenue * (1 + (Math.floor(5 * Math.random()) / 5) * 5)) /
//             1000000
//         );
//     const albacrossRevealExample = {
//       company: {
//         name: 'Albacross',
//         country: 'SE',
//         url: 'albacross.com',
//         number: 'SE123282543',
//         description: 'Welcome to Albacross!',
//         founded_year: 2013,
//         address: {
//           address: 'Kungsgatan 26',
//           city: 'Stockholm',
//           state: 'Stockholms Lan',
//           zip_code: '19587',
//         },
//         linkedin_url: 'https://www.linkedin.com/company/albacross',
//         employees: {
//           from: 5000,
//           to: 100000,
//           range: '5000-100000',
//         },
//         financial_report: {
//           from: `${randomRevenue}`,
//           to: `${randomRevenueMax}`,
//           range: `${randomRevenue}-${randomRevenueMax}`,
//         },
//         nace_code: {
//           code: '73',
//           category: 'Advertising and market research',
//         },
//       },
//     };

//     const linkedInIndustryCodes = [
//       {
//         code: '4',
//         category: 'Software Development',
//       },
//       {
//         code: '133',
//         category: 'Wholesale',
//       },
//       {
//         code: '47',
//         category: 'Accounting',
//       },
//       {},
//     ];

//     const randomLinkedInCategory =
//       linkedInIndustryCodes[
//         Math.floor(Math.random() * linkedInIndustryCodes.length)
//       ];

//     albacrossRevealExample.company.linkedin_industry_code =
//       randomLinkedInCategory;

//     const companyName = `${albacrossRevealExample.company.name} ${
//       Math.random() > 0.7 || window.location.search.indexOf('onlyday') > -1
//         ? Math.floor(
//             (new Date() - new Date(new Date().getFullYear(), 0, 0)) /
//               (1000 * 60 * 60 * 24)
//           )
//         : new Date().toLocaleString('sv-SE')
//     }`;

//     const companyCountry = albacrossRevealExample.company.country;
//     const companyFounded = albacrossRevealExample.company.founded_year;
//     const companyAlexaRank = albacrossRevealExample.company.alexa_rank;
//     const companyEmployeeMin = albacrossRevealExample.company.employees
//       ? albacrossRevealExample.company.employees.from
//       : '';

//     const companyEmployeeMax = albacrossRevealExample.company.employees
//       ? albacrossRevealExample.company.employees.to
//       : '';
//     const companyLinkedInCategory = albacrossRevealExample?.company
//       ?.linkedin_industry_code?.category
//       ? albacrossRevealExample.company.linkedin_industry_code?.category
//       : '';
//     const companyLinkedInCode = albacrossRevealExample?.company
//       ?.linkedin_industry_code
//       ? albacrossRevealExample.company.linkedin_industry_code.code
//       : '';

//     const companyFinancialsMin = albacrossRevealExample.company.financial_report
//       ? albacrossRevealExample.company.financial_report.from
//       : '';
//     const companyFinancialsMax = albacrossRevealExample.company.financial_report
//       ? albacrossRevealExample.company.financial_report.to
//       : '';
//     const companNACECategory = albacrossRevealExample.company.nace_code
//       ? albacrossRevealExample.company.nace_code.category
//       : '';
//     const companyCity = albacrossRevealExample.company.address.city;
//     const companyState = albacrossRevealExample.company.address.state;
//     const companyOrgID = albacrossRevealExample.company.number;
//     const companyZip = albacrossRevealExample.company.address.zip_code
//       ? albacrossRevealExample.company.address.zip_code
//       : '';
//     const companyWebsite = albacrossRevealExample.company.url
//       ? albacrossRevealExample.company.url
//       : '';

//     const linkedInUrl = albacrossRevealExample.company.linkedin_url || '';

//     const query = JSON.stringify({
//       query: `mutation {
//             createLeadsAlbacrossReveal(
//               name: "${companyName}"
//               founded: "${companyFounded || ''}"
//               country: "${companyCountry}"
//               alexaRank: "${companyAlexaRank || ''}"
//               employeeMin: "${companyEmployeeMin}"
//               employeeMax: "${companyEmployeeMax}"
//               linkedInCategoryCode: "${parseInt(companyLinkedInCode)}"
//               linkedInCategory: "${companyLinkedInCategory || ''}"
//               linkedInUrl: "${linkedInUrl}"
//               financialsMin: "${companyFinancialsMin}"
//               financialsMax: "${companyFinancialsMax}"
//               naceCategory: "${companNACECategory}"
//               city: "${companyCity || ''}"
//               state: "${companyState || ''}"
//               orgId: "${companyOrgID || ''}"
//               zip: "${companyZip || ''}"
//               website: "${companyWebsite}"
//               locale: "${locale}"
//               url: "${window.location.href}"
// 							sessionId: "${sessionStorage.getItem('sessionId')}"
//             ) { result }
//           }
//           `,
//     });

//     fetch(process.env.GATSBY_BE_URL_DEV, {
//       headers: { 'content-type': 'application/json' },
//       method: 'POST',
//       body: query,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then((response) => {
//         const id = response?.data?.createLeadsAlbacrossReveal?.result;
//         sessionStorage.setItem('reveal', id);
//       });
//   } catch (error) {
//     console.log('Error with A dev', error);
//   }

//   return true;
// }

export const onClientEntry = () => {
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', uuidv4());
  }
  if (!sessionStorage.getItem('landingPage')) {
    sessionStorage.setItem('landingPage', window.location.href);
  }
};

export const onInitialClientRender = () => {
  if (process.env.GATSBY_BW_ENV === 'prod') {
    albacrossReveal();
  } /* else {
    albacrossRevealDev();
  }
  */

  // To remove the ugly focus when using mouse
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse');
  });
  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse');
  });
};
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    setTimeout(() => {
      const isRevealed = sessionStorage.getItem('reveal') || false;
      if (isRevealed) {
        const query = JSON.stringify({
          query: `mutation {
            updateLeadsAlbacrossReveal(
              id: "${isRevealed}"
              url: "${window.location.href}"
            ) { result }
          }
          `,
        });

        fetch(process.env.GATSBY_BE_URL_DEV, {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: query,
        });
      }
    }, 100);
  }

  let referrer = '';
  if (document.referrer && !prevLocation) {
    referrer = document.referrer;
  } else {
    referrer = prevLocation ? prevLocation.href : '';
  }

  const urlParams = new URLSearchParams(window.location.search);
  const originalReferrer = sessionStorage.getItem('adOriginalReferrer');

  if (!originalReferrer && document.referrer) {
    sessionStorage.setItem('adOriginalReferrer', referrer);
  }

  const adGclid = urlParams.get('gclid');
  if (adGclid) {
    sessionStorage.setItem('adGclid', adGclid);
  }

  const digParam = urlParams.get('dig');
  if (digParam) {
    sessionStorage.setItem('adCode', digParam);
  }
  if (document.body.classList.contains('overflow-hidden')) {
    document.body.classList.remove('overflow-hidden');
  }
};

export const wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <I18nextProvider i18n={i18n}>
    <GlobalStyles />
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </I18nextProvider>
);
